﻿import {Component, Injector, OnInit} from '@angular/core';
import {SettingsService} from '../../../core/settings/settings.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from 'ng2-validation';
import {BaseComponent, MessageType} from "../../../core/component/base-component";
import {RestaurantUserService} from "../../../services/restaurant-user/restaurant-user.service";

const swal = require('sweetalert');

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent extends BaseComponent implements OnInit {

  valForm: FormGroup;

  constructor(public settings: SettingsService, fb: FormBuilder, injector: Injector, private restaurantUserService: RestaurantUserService) {
    super(injector);
    this.valForm = fb.group({
      'email': [null, Validators.compose([Validators.required, CustomValidators.email])]
    });
  }

  ngOnInit() {
  }

  async submitForm($ev, value: any, form) {
    $ev.preventDefault();
    if (!this.valForm.valid) {
      return;
    }

    let translateResult = await this.translate(['shared.Question', 'recover.DoYouWantToResetYourPassword', 'shared.No', 'shared.Yes']);
    await swal({
      title: translateResult['shared.Question'],
      text: translateResult['recover.DoYouWantToResetYourPassword'],
      icon: 'info',
      closeOnClickOutside: false,
      buttons: {
        cancel: {
          text: translateResult['shared.No'],
          value: false,
          visible: true,
          className: "bg-default",
          closeModal: true,
        },
        confirm: {
          text: translateResult['shared.Yes'],
          value: true,
          visible: true,
          className: "bg-danger",
          closeModal: true,
        }
      }
    }).then(async (isConfirm) => {
      if (isConfirm == true) {
//        await this.restaurantUserService.resetPassword(this.valForm.controls['email'].value);

        await this.restaurantUserService.resetPassword(this.valForm.controls['email'].value);

        let successTranslateResult = await this.translate(['recover.PasswordIsReset', 'shared.Info']);
        this.valForm.controls['email'].setValue(null);
        form.resetForm();
        this.pop(MessageType.Success, successTranslateResult['shared.Info'], successTranslateResult['recover.PasswordIsReset']);
      }
    });
  }
}

