import { Component, OnInit } from '@angular/core';

import { UserblockService } from './userblock.service';
import {RestaurantUserModel} from "../../../models/restaurant/user/restaurant-user.model";
import {RestaurantUserService} from "../../../services/restaurant-user/restaurant-user.service";
import {StorageService} from "../../../services/shared/storage.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    user: RestaurantUserModel;
    constructor(public userblockService: UserblockService, private storageService:StorageService,private restaurantUserService:RestaurantUserService, private router:Router) {

        // this.user = {
        //     picture: 'assets/img/user/01.jpg'
        // };
    }

    ngOnInit() {
      this.user = this.storageService.restaurantUser;
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

  logout(){
    this.restaurantUserService.logout();
    this.router.navigate(["/login"]).then();
  }

}
