﻿import {Pipe, PipeTransform} from '@angular/core';
import {ToppingGroupModel} from "../../../models/product/topping-group.model";

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'toppingGroupFilter'})
export class ToppingGroupFilterPipe implements PipeTransform {
  transform(list: ToppingGroupModel[], name: string): any[] {
    if (!name) {
      return list;
    }
    return list.filter(
      p => p.name.toLowerCase().indexOf(
        name.toLowerCase()) >= 0 || (p.displayName && p.displayName.toLowerCase().indexOf(
        name.toLowerCase()) >= 0) || (p.toppings && p.toppings.find(
        t => t.name.toLowerCase().indexOf(name.toLowerCase()) >= 0)));
  }
}
