import { LayoutComponent } from '../layout/layout.component';
import { LoginComponent } from "./pages/login/login.component";
import { RecoverComponent } from "./pages/recover/recover.component";
import { Error404Component } from "./pages/error404/error404.component";
import { Error500Component } from "./pages/error500/error500.component";
import { AuthenticationGuard } from "../shared/guards/authentication.guard";

export const routes = [

  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'restaurant',
        loadChildren: () => import('./restaurant/restaurant.module').then(m => m.RestaurantModule),
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'user',
        loadChildren: () => import('./restaurant-user/restaurant-user.module').then(m => m.RestaurantUserModule),
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'menu-setup',
        loadChildren: () => import('./menu-setup/menu-setup.module').then(m => m.MenuSetupModule),
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'online-payment',
        loadChildren: () => import('./online-payment/online-payment.module').then(m => m.OnlinePaymentModule),
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'discount',
        loadChildren: () => import('./discount/discount.module').then(m => m.DiscountsModule),
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'content',
        loadChildren: () => import('./content/content.module').then(m => m.ContentsModule),
        canActivate: [AuthenticationGuard]
      },
      {
        path: 'notification',
        loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule),
        canActivate: [AuthenticationGuard],
        data: { permissions: ['NotificationSubscription'] },
      },
      {
        path: 'admin',
        loadChildren: () => import('./super-admin/super-admin.module').then(m => m.SuperAdminModule),
        canActivate: [AuthenticationGuard],
        data: {permissions: ['SuperAdmin']},
      }
    ]
  },

  { path: 'login', component: LoginComponent },
  { path: 'recover', component: RecoverComponent },
  { path: '404', component: Error404Component },
  { path: '500', component: Error500Component },

  // Not found
  { path: '**', redirectTo: 'home' }

];

