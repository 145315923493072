﻿import {UtilityService} from "../../../services/shared/utility.service";

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'currencyDisplay'})
export class CurrencyDisplayPipe implements PipeTransform {

  constructor(private utilityService:UtilityService){

  }

  transform(value:number): string {
    return this.utilityService.formatCurrency(value);
  }
}
