﻿import { Pipe, PipeTransform } from '@angular/core';
import {ProductModel} from "../../../models/product/product.model";
import {MealDealProductModel} from "../../../models/product/meal-deal-product.model";
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'mealDealProductFilter'})
export class MealDealProductFilterPipe implements PipeTransform {
  transform(list:MealDealProductModel[], name:string): any[] {
    if(!name){
      return list;
    }
    return list.filter(p=>p.name.toLowerCase().indexOf(name.toLowerCase())>=0);
  }
}
