﻿import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {RestaurantModel} from "../../models/restaurant/restaurant.model";
import {StorageService} from "../shared/storage.service";
import "rxjs-compat/add/operator/map";
import {Router} from "@angular/router";
import {MealDealSettingModel} from "../../models/restaurant/meal-deal-setting.model";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"} as any)
export class RestaurantService {
  private service = "restaurant";

  private guids: string[];

  constructor(private http: HttpClient, private storageService: StorageService, private router: Router) {

  }

  // public async getRestaurant(): Promise<RestaurantModel> {
  //   let restaurantUser = this.storageService.restaurantUser;
  //   if (!restaurantUser) {
  //     await this.router.navigate(["/login"]);
  //     return;
  //   }
  //
  //   let restaurant = this.storageService.restaurant;
  //   if (!restaurant) {
  //     restaurant = await this.getPromise(restaurantUser.restaurantId);
  //     this.storageService.restaurant = restaurant;
  //   }
  //
  //   return restaurant;
  // }

  async getGuid() {
    if (!this.guids || this.guids.length <= 0) {
      await this.createGuidList();
    }
    let lastGuid = this.guids[this.guids.length - 1];
    this.guids = this.guids.filter(p => p != lastGuid);
    return lastGuid;
  }

  // get(id: string): any {
  //   this.http.get<RestaurantModel>(`${this.service}/get/${id}`).subscribe(result => {
  //     this.storageService.restaurant = result;
  //   });
  // }

  getPromise(id: string): Promise<RestaurantModel> {
    return this.http.get<RestaurantModel>(`${this.service}/get/${id}`).toPromise();
  }

  // update(restaurant: RestaurantModel): Promise<any> {
  //   let postData = JSON.parse(JSON.stringify(restaurant, this.replacer));
  //
  //   return this.http.put(`${this.service}/update`, postData).map(result => {
  //     this.storageService.restaurant = postData;
  //   }).toPromise();
  // }

  //TODO ortak biryere al
  public replacer(key, value) {
    if (key == "shape") {
      return undefined;
    }
    else {
      return value;
    }
  }

  createGuidList(): Promise<any> {
    return this.http.get<string[]>(`${this.service}/createGuidList`).map(result => {
      this.guids = result;
    }).toPromise();
  }

  async updateMealDealMobileImage(imageName: string): Promise<MealDealSettingModel> {
    return this.http.put<MealDealSettingModel>(`${this.service}/updateMealDealImage`, {imageName}).toPromise()
  }

  uploadMealDealSettingsMobileImage(fileToUpload: string, fileName: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('image', this.dataURIToBlob(fileToUpload), fileName);
    return this.http.post<any>(`${this.service}/UploadMealDealSettingsMobileImage/`, formData);
  }

  public async deleteMealDealSettingsMobileImage(): Promise<any> {
    return this.http.delete(`${this.service}/DeleteMealDealSettingsMobileImage`).toPromise();
  }

  public async deleteMealDealSettingsWebImage(): Promise<any> {
    return this.http.delete(`${this.service}/DeleteMealDealSettingsWebImage`).toPromise();
  }

  uploadMealDealSettingsWebImage(fileToUpload: string, fileName: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('image', this.dataURIToBlob(fileToUpload), fileName);
    return this.http.post<any>(`${this.service}/UploadMealDealSettingsWebImage/`, formData);
  }

  //TODO ortak bieyre alinacak, categoryde de var
  dataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ia], {type: mimeString})
  }

  // updateMealDealTitle(mealDealTitle: string):Promise<MealDealSettingModel> {
  //   return this.http.put<MealDealSettingModel>(`${this.service}/UpdateMealDealTitle`, {mealDealTitle}).toPromise()
  // }
  //
  // deleteMealDealImage() {
  //   return this.http.delete(`${this.service}/DeleteMealDealImage`).toPromise();
  // }
}
