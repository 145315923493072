﻿import {Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {BsModalRef, ModalDirective} from "ngx-bootstrap";
import {GlobalImageType} from "../../../models/enums";
import {Bounds, CropperSettings, ImageCropperComponent} from "ng2-img-cropper";
import {ItemImageModel} from "../../../models/product/item-image.model";
import {GlobalImageModel} from "../../../models/product/global-image.model";
import {GlobalImageService} from "../../../services/product/global-image.service";
import {SettingsService} from "../../../core/settings/settings.service";


@Component({
  selector: 'image-upload',
  styleUrls: ['./image-upload.component.scss'],
  templateUrl: './image-upload.component.html'
})
export class ImageUploadComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

  @ViewChild('cropper', undefined) cropper: ImageCropperComponent;

  @ViewChild('cropperModal', {static: false})
  public cropperModal: ModalDirective;

  @ViewChild('imagesModal', {static: false})
  public imagesModal: ModalDirective;

  imageBounds: Bounds;
  cropperSettings: CropperSettings;
  croppedImage: any;
  globalImages: GlobalImageModel[];

  uploadImageFunction;
  selectGlobalImageFunction;

  existingImageName: string;
  isExistingImageGlobal: boolean;

  imageUploadRequestSubscription;
  isModalVisible: boolean = false;

  constructor(private globalImageService: GlobalImageService, public settingsService: SettingsService) {
    this.cropperSettings = new CropperSettings();

    this.croppedImage = {};
  }

  public ngOnInit() {
    if(!this.imageUploadRequestSubscription){
      this.imageUploadRequestSubscription = this.globalImageService.onImageUploadRequest.subscribe((data) => {
        this.cropperSettings = data.cropperSettings;
        this.existingImageName = data.existingImageName;
        this.isExistingImageGlobal = data.isExistingImageGlobal;
        this.uploadImageFunction = data.uploadImageFunction;
        this.selectGlobalImageFunction = data.selectGlobalImageFunction;
        this.getImages(data.imageType, data.word).then();
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  async getImages(imageType: GlobalImageType, name: string) {
    this.globalImages = await this.globalImageService.list(imageType, name);
    if (!this.globalImages || this.globalImages.length <= 0) {
      let inputElement: HTMLElement = this.fileInput.nativeElement as HTMLElement;
      //inputElement.click();
      const evt = document.createEvent("MouseEvents");
      evt.initEvent("click", true, false);
      inputElement.dispatchEvent(evt);
    }
    else {
      this.imagesModal.show();
    }
  }

  cropped(bounds: Bounds) {
    this.imageBounds = bounds;
    console.log(bounds);
  }

  fileChangeListener($event: any) {
    this.isModalVisible = true;
    let image: any = new Image();
    if (!$event.target.files || $event.target.files.length <= 0) {
      this.isModalVisible = false;
      return;
    }
    let file: File = $event.target.files[0];
    let myReader: FileReader = new FileReader();

    myReader.onloadend = (loadEvent: any) => {
      image.src = loadEvent.target.result;
      this.cropper.setImage(image);
      this.cropperModal.show();
    };

    myReader.readAsDataURL(file);
  }

  uploadImage() {
    this.uploadImageFunction(this.croppedImage.image, this.fileInput.nativeElement.files[0].name, () => {
      this.cropperModal.hide();
      this.fileInput.nativeElement.value = null;
      this.isModalVisible = false;
    });
  }

  async selectGlobalImage(imageName: string) {
    this.selectGlobalImageFunction(imageName);
    this.imagesModal.hide();
    this.isModalVisible = false;
  }

  openUploadImagePopup() {
    this.imagesModal.hide();
    let inputElement: HTMLElement = this.fileInput.nativeElement as HTMLElement;
    inputElement.click();
  }

  ngOnDestroy(): void {
    this.imageUploadRequestSubscription.unsubscribe();
  }

  modalClosed() {
    this.isModalVisible = false;
  }
}
