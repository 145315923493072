﻿import {Component} from '@angular/core';


@Component({
  selector: 'page-loading',
  styleUrls: ['./page-loading.component.scss'],
  templateUrl: './page-loading.component.html'
})
export class PageLoadingComponent {
}
