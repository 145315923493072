﻿import {ActivatedRoute, Router} from '@angular/router';
import {ToasterService} from 'angular2-toaster/angular2-toaster';
import {Injector} from "@angular/core";
import {TranslateService} from '@ngx-translate/core';
import {SettingsService} from "../settings/settings.service";


export class BaseComponent {

  private toasterService: ToasterService;
  private translateService: TranslateService;
  protected activatedRoute: ActivatedRoute;
  protected router: Router;
  public settingsService: SettingsService;

  constructor(injector: Injector) {
    this.toasterService = injector.get(ToasterService);
    this.translateService = injector.get(TranslateService);
    //this.activatedRoute = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.settingsService = injector.get(SettingsService);
  }

  pop(type: MessageType, title: string, message: string) {
    let typeName = '';
    if (type == MessageType.Error) {
      typeName = 'error';
    } else if (type == MessageType.Success) {
      typeName = 'success';
    } else {
      typeName = 'warning';
    }
    this.toasterService.pop(typeName, title, message);
  }

  async translate(keys: string[]) {
    return await this.translateService.get(keys).toPromise();
  }

  parseFloatCustom(text: string): number {
    if (!text) {
      return 0;
    }
    let decimalSeparator = this.settingsService.getAppSetting('decimalSeparator');
    if (decimalSeparator == ',') {
      text = text.toString().replace(',', '.');
    }
    return parseFloat(text);
  }

  //format 25.01.2020
  convertToDateTime(text){
    let parts =text.split('.');
    return new Date(parts[2]+'-'+parts[1]+'-'+parts[0]);
  }
}

export enum MessageType {
  Success = 1,
  Warning = 2,
  Error = 3,
}
