﻿import {Injectable} from "@angular/core";
import {SettingsService} from "../../core/settings/settings.service";
import {StorageService} from "./storage.service";


@Injectable({providedIn: "root"} as any)
export class UtilityService {

  constructor(private settingsService: SettingsService, private storageService: StorageService) {
  }

  formatCurrency(value: number) {
    if(isNaN(value)){
      return '';
    }
    //let restaurant = this.storageService.restaurant;
    let formatter;
    if(this.settingsService.getAppSetting('decimalSeparator') =='.'){
      formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        //currency: restaurant.generalInformation.selectedCurrency.acronym,
        currency: 'CHF',
      });
    }else{
      formatter = new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        //currency: restaurant.generalInformation.selectedCurrency.acronym,
        currency: 'CHF',
      });
    }
    return formatter.format(value);
  }

  formatDecimal(value:number, returnZeroWhenUndefined=false){
    if(isNaN(value)){
      return '';
    }
    if(value == undefined){
      if(returnZeroWhenUndefined){
        return '0'+this.settingsService.getAppSetting('decimalSeparator')+'00';
      }
      else{
        return '';
      }
    }
    let formatter: Intl.NumberFormat;
    if(this.settingsService.getAppSetting('decimalSeparator') =='.'){
      formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits:2,
        maximumFractionDigits:2
      });
    }else{
      formatter = new Intl.NumberFormat('tr-TR', {
        style: 'decimal',
        minimumFractionDigits:2,
        maximumFractionDigits:2
      });
    }
    return formatter.format(value);
  }

}
