import {Injectable} from "@angular/core";
import {BsModalService} from "ngx-bootstrap";
import {BsModalRef} from "ngx-bootstrap";
import {ModalContentComponent} from "../../shared/components/modal/modalcontent.component";

@Injectable({providedIn: "root"} as any)
export class ModalService {
  public bsModalRef: BsModalRef;
  public isShown: boolean = false;

  constructor(private modalService: BsModalService) {
    this.modalService.onHide.subscribe(() => {
      this.isShown = false;
    })

  }


  public async  showModal(message: string, title = "Error") {
    if (this.isShown) {
      return;
    }
    this.isShown = true;
    const initialState = {
      title: title,
      content:message
    };
    this.bsModalRef = this.modalService.show(ModalContentComponent, {initialState});
  }
}
