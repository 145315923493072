import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import "rxjs-compat/add/observable/of";
import {StorageService} from "../../services/shared/storage.service";
import {SettingsService} from "../../core/settings/settings.service";
import {TranslateService} from "@ngx-translate/core";
import {TranslatorService} from "../../core/translator/translator.service";

const swal = require('sweetalert');

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(private router: Router, private storageService: StorageService, private settingsService: SettingsService,
              private translateService: TranslateService, private translatorService: TranslatorService) {

  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      if (this.translatorService) {
        const selectedLanguage = this.translatorService.getSelectedLanguage();
        this.storageService.clear();
        this.translatorService.useLanguage(selectedLanguage);
      }

      this.router.navigateByUrl(`/login`);
      return Observable.of(err.message);
    }

    return throwError(err);
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler) {
    let url = request.url;

    if (!url.startsWith("http")
      && !url.startsWith('./')) {
      url = this.settingsService.getApiUrl() + 'api/' + url;
    }

    if (url.toLowerCase().indexOf('upload') >= 0) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.storageService.token}`,
        },
        url
      });
    }
    else {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.storageService.token}`,
          Language: `${this.translateService.currentLang}`,
          'Content-Type': 'application/json; charset=utf-8'
        },
        url
      });
    }

    request = request.clone({url});
    return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
  }
}

