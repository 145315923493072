import { Injectable } from '@angular/core';

@Injectable({ providedIn: "root" } as any)
export class SettingsService {

    private user: any;
    private app: any;
    private layout: any;

    constructor() {

        // App Settings
        // -----------------------------------
        this.app = {
            name: 'Order Star',
            description: 'Online Ordering System',
            year: ((new Date()).getFullYear()),
            decimalSeparator: '.'
        };

        // Layout Settings
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: false,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };

    }

    getAppSetting(name) {
        return name ? this.app[name] : this.app;
    }

    getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

    getApiUrl() {
        //return 'http://localhost:5002/';
        return 'https://test.restaurant-admin-api.orderstar.ch/';
        return 'https://restaurant-admin-api.orderstar.ch/';
    }
}
