const Home = {
  text: 'Home',
  translate: 'Home',
  link: '/home',
  icon: 'icon-home'
};

const headingMain = {
  text: 'Main Navigation',
  translate: 'MainNavigation',
  heading: true
};

const Restaurant = {
  text: 'Restaurant',
  translate: 'Restaurant',
  icon: 'icon-layers',
  submenu: [
    {
      text: 'General Information',
      translate: 'GeneralInformation',
      link: '/restaurant/general-information'
    },
    {
      text: 'General Settings',
      translate: 'GeneralSettings',
      link: '/restaurant/general-settings'
    },
    {
      text: 'Location',
      translate: 'Location',
      link: '/restaurant/location'
    },
    {
      text: 'Delivery Zones',
      translate: 'DeliveryZones',
      link: '/restaurant/delivery-zones'
    },
    {
      text: 'Service Times',
      translate: 'ServiceTimes',
      link: '/restaurant/service-times'
    },
    {
      text: 'Taxes',
      translate: 'Taxes',
      link: '/restaurant/taxes'
    },
    // {
    //   text: 'Languages',
    //   link: '/restaurant/languages'
    // }

  ]
};

const MenuSetup = {
  text: 'Menu Setup',
  translate: 'MenuSetup',
  icon: 'icon-book-open',
  submenu: [
    {
      text: 'Menu',
      translate: 'Menu',
      link: '/menu-setup/menu'
    },
    {
      text: 'Meal Deals',
      translate: 'MealDeals',
      link: '/menu-setup/meal-deals'
    }
  ]
};

const RestaurantUser = {
  text: 'User',
  translate: 'User',
  icon: 'icon-user',
  submenu: [
    {
      text: 'Profile',
      translate: 'Profile',
      link: '/user/profile'
    }
  ]
};

const Reports = {
  text: 'Reports',
  translate: 'Reports',
  icon: 'icon-list',
  submenu: [
    {
      text: 'Orders',
      translate: 'Orders',
      link: '/reports/orders'
    },
    {
      text: 'Customers',
      translate: 'Customers',
      link: '/reports/customers'
    }
  ]
};

const OnlinePayment = {
  text: 'Online Payment',
  translate: 'OnlinePayment',
  icon: 'icon-credit-card',
  submenu: [
    {
      text: 'SaferPay',
      translate: 'SaferPay',
      link: '/online-payment/safer-pay'
    },
    {
      text: 'Payrexx',
      translate: 'Payrexx',
      link: '/online-payment/payrexx'
    }
  ]
}

const Discount = {
  text: 'Discount',
  translate: 'Discount',
  icon: 'icon-tag',
  submenu: [
    {
      text: 'Discounts',
      translate: 'Discounts',
      link: '/discount/discounts'
    }
  ]
}

const Content = {
  text: 'Content',
  translate: 'Content',
  icon: 'icon-notebook',
  submenu: [
    {
      text: 'Contents',
      translate: 'Contents',
      link: '/content/contents'
    },
    {
      text: 'WebContents',
      translate: 'WebContents',
      link: '/content/web-contents'
    }
  ]
}

const Notification = {
  text: 'Notification',
  translate: 'Notification',
  icon: 'icon-bell',
  submenu: [
    {
      text: 'CustomerGroups',
      translate: 'CustomerGroups',
      link: '/notification/customer-groups',
      data: {
        permission: 'NotificationSubscription'
      }
    },
    {
      text: 'Send Notification',
      translate: 'SendNotification',
      link: '/notification/notification-sessions',
      data: {
        permission: 'NotificationSubscription'
      }
    }
  ]
}

export const menu = [
  headingMain,
  Home,
  RestaurantUser,
  Restaurant,
  Discount,
  Content,
  //OnlinePayment,
  MenuSetup,
  Notification,
  Reports
];

