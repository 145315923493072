import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {ToasterModule} from 'angular2-toaster/angular2-toaster';

import {AccordionModule} from 'ngx-bootstrap/accordion';
import {AlertModule} from 'ngx-bootstrap/alert';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {RatingModule} from 'ngx-bootstrap/rating';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {DatepickerModule} from 'ngx-bootstrap/datepicker';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';

import {FlotDirective} from './directives/flot/flot.directive';
import {SparklineDirective} from './directives/sparkline/sparkline.directive';
import {EasypiechartDirective} from './directives/easypiechart/easypiechart.directive';
import {ColorsService} from './colors/colors.service';
import {CheckallDirective} from './directives/checkall/checkall.directive';
import {VectormapDirective} from './directives/vectormap/vectormap.directive';
import {NowDirective} from './directives/now/now.directive';
import {ScrollableDirective} from './directives/scrollable/scrollable.directive';
import {JqcloudDirective} from './directives/jqcloud/jqcloud.directive';
import {InterceptorsModule} from "./interceptors/interceptors.module";
import {HandlersModule} from "./handlers/handlers.module";
import {GuardsModule} from "./guards/guards.module";
import {ModalContentComponent} from "./components/modal/modalcontent.component";
import {IdNotInPipe} from "./pipes/id-not-in/id-not-int.pipe";
import {DecimalDirective} from "./directives/decimal/decimal.directive";
import {NumericDirective} from "./directives/numeric/numeric.directive";
import {CurrencyDisplayPipe} from "./pipes/currency-display/currency-display.pipe";
import {DecimalDisplayPipe} from "./pipes/decimal-display/decimal-display.pipe";
import {MealDealProductFilterPipe} from "./pipes/meal-deal-product-filter/meal-deal-product-filter.pipe";
import {ToppingGroupFilterPipe} from "./pipes/topping-group-filter/topping-group-filter.pipe";
import {PageLoadingComponent} from "./components/page-loading/page-loading.component";
import {ImageUploadComponent} from "./components/image-upload/image-upload.component";
import {ImageCropperModule} from "ng2-img-cropper";
import {CustomerFilterPipe} from './pipes/customer-filter/customer-filter.pipe';
import {WebContentFilterPipe} from './pipes/web-content-filter/web-content-filter.pipe';
import { ImagesComponent } from './components/images/images.component'
import {RestaurantsFilterPipe} from "./pipes/restaurants-filter/restaurants-filter.pipe";


// https://angular.io/styleguide#!#04-10
@NgModule({
  entryComponents: [
    ModalContentComponent,
    PageLoadingComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    ToasterModule,
    ImageCropperModule
  ],
  providers: [
    ColorsService
  ],
  declarations: [
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    ModalContentComponent,
    PageLoadingComponent,
    IdNotInPipe,
    DecimalDirective,
    NumericDirective,
    CurrencyDisplayPipe,
    DecimalDisplayPipe,
    MealDealProductFilterPipe,
    CustomerFilterPipe,
    ToppingGroupFilterPipe,
    RestaurantsFilterPipe,
    ImageUploadComponent,
    WebContentFilterPipe,
    ImagesComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    DatepickerModule,
    BsDatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    PopoverModule,
    TypeaheadModule,
    ToasterModule,
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    InterceptorsModule,
    HandlersModule,
    GuardsModule,
    IdNotInPipe,
    DecimalDirective,
    NumericDirective,
    CurrencyDisplayPipe,
    DecimalDisplayPipe,
    MealDealProductFilterPipe,
    CustomerFilterPipe,
    ToppingGroupFilterPipe,
    RestaurantsFilterPipe,
    PageLoadingComponent,
    PageLoadingComponent,
    ImageUploadComponent,
    WebContentFilterPipe,
    ImagesComponent
  ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
