import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class MenuService {


  menuItems: Array<any>;

  constructor(private translateService: TranslateService) {
    this.menuItems = [];
  }

  async addMenu(items: Array<any>) {
    for (const item of items) {
      // this.translateService.get(item.text)._subscribe(result=>{
      //   item.text = result;
      // })
      //item.text = await this.translateService.get('sideMenu.' + item.text).toPromise();
      // if(item.submenu){
      //   for (const subMenu of item.submenu) {
      //     subMenu.text = await this.translateService.get('sideMenu.' + subMenu.text).toPromise();
      //   }
      // }
      this.menuItems.push(item);
    }
  }

  getMenu() {

    return this.menuItems;
  }

}
