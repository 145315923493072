import {ErrorHandler, Injectable, Injector} from "@angular/core";
import {Router} from "@angular/router";
import {ModalService} from "../../services/shared/modal.service";
import {ApplicationError} from "./applicationerror";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

  private translateService: TranslateService;

  constructor(private injector: Injector) {
    this.translateService = injector.get(TranslateService);
  }

  public handleError(error: any) {
    if (error.promise && error.rejection) {
      // Promise rejection wrapped by zone.js
      error = error.rejection;
    }
    console.error(error);
    if (error.handled) {
      console.error(error);
      return;
    }

    let message;

    this.showModal(error);

    //if (error instanceof ApplicationError) {
    // if (error.error && error.error.message) {
    //   this.translateService.get(['shared.ErrorOccured', 'shared.Error', 'messageCodes.'+ error.error.message]).subscribe(translateResult => {
    //     this.showModal(error.error, translateResult['messageCodes.'+ error.error.message] + (error.error.parameters?(' '+error.error.parameters.join()):''), translateResult['shared.Error']);
    //   });
    // } else {
    //   this.translateService.get(['shared.ErrorOccured', 'shared.Error']).subscribe(translateResult => {
    //     this.showModal(error.error, translateResult['shared.ErrorOccured'], translateResult['shared.Error']);
    //   });
    // }
    // } else {
    //   this.translateService.get(['shared.ErrorOccured']).subscribe(translateResult=>{
    //     this.showModal(error, translateResult['shared.ErrorOccured']);
    //   });
    //}
  }

  showModal(error) {
    const modalService = this.injector.get(ModalService);
    const router = this.injector.get(Router);
    switch (error.status) {
      case 400: {
        this.translateService.get(['shared.ErrorOccured', 'shared.Error', 'messageCodes.'+ error.error.message]).subscribe(translateResult => {
          modalService.showModal(translateResult['messageCodes.'+ error.error.message] + (error.error.parameters?(' '+error.error.parameters.join()):''), translateResult['shared.Error']).then();
        });
        break;
      }
      case 401: {
        router.navigate(["/login"]).then();
        break;
      }
      case 404: {
        router.navigate(["/home"]).then();
        break;
      }
      case 500: {
        this.translateService.get(['shared.ErrorOccured', 'shared.Error']).subscribe(translateResult => {
          modalService.showModal(translateResult['shared.ErrorOccured'], translateResult['shared.Error']).then();
        });
        break;
      }
      default: {
        this.translateService.get(['shared.ErrorOccured', 'shared.Error']).subscribe(translateResult => {
          modalService.showModal(translateResult['shared.ErrorOccured'], translateResult['shared.Error']).then();
        });
        break;
      }
    }
  }
}

//TODO islem yapilirken bir tane processing gosterelim biyerlerde
