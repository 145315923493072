import {Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BaseComponent} from 'src/app/core/component/base-component';
import {CategoryService} from 'src/app/services/menu/category.service';
import {GlobalImageService} from 'src/app/services/product/global-image.service';
import {CropperSettings} from "ng2-img-cropper";
import {ItemImageModel} from "../../../models/product/item-image.model";

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss']
})
export class ImagesComponent extends BaseComponent implements OnInit, OnChanges {

  @Input()
  imageUrl: string;
  @Input()
  image: ItemImageModel;
  @Input()
  uploadImageFunction: any;
  @Input()
  selectGlobalImageFunction: any;
  @Input()
  deleteImageFunction: any;
  @Input()
  imageCropperSettings: CropperSettings;
  @Input()
  word: string;
  @Input()
  globalImageType: any;
  @Input()
  imageWidth: number;
  @Input()
  imageHeight: number;
  @Input()
  maxSize: number = 59;

  newHeight: string;
  newWidth: string;


  constructor(private categoryService: CategoryService, injector: Injector, private globalImageService: GlobalImageService) {
    super(injector);
  }

  ngOnInit() {
    this.setImageSize();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setImageSize();
  }

  setImageSize(){
    if (this.imageUrl) {
      this.calculateImageSize();
    }
    else {
      this.newHeight = this.maxSize + 'px';
      this.newWidth = this.maxSize + 'px'
    }
  }

  uploadImage($event: MouseEvent) {
    $event.stopPropagation();
    let existingImageName = null;
    let isExistingImageGlobal = false;
    if (this.image) {
      existingImageName = this.image.imageName;
      isExistingImageGlobal = this.image.isGlobal;
    }

    const imageUploadPayload = {
      word: this.word,
      imageType: this.globalImageType,
      uploadImageFunction: this.uploadImageFunction,
      selectGlobalImageFunction: this.selectGlobalImageFunction,
      existingImageName: existingImageName,
      isExistingImageGlobal,
      cropperSettings: this.imageCropperSettings
    }

    this.globalImageService.onImageUploadRequest.next(imageUploadPayload);
  }

  deleteImage($event) {
    $event.stopPropagation();
    this.deleteImageFunction();
  }

  private calculateImageSize() {

    let newWidth = this.imageWidth;
    let newHeight = this.imageHeight;

    if (this.imageWidth > this.maxSize) {
      newWidth = this.maxSize;
      newHeight = this.maxSize * this.imageHeight / this.imageWidth;

      if (newHeight > this.maxSize) {
        newHeight = this.maxSize;
        newWidth = this.maxSize * this.imageWidth / this.imageHeight;
      }
    }
    else {
      if (this.imageHeight > this.maxSize) {
        if (newHeight > this.maxSize) {
          newHeight = this.maxSize;
          newWidth = this.maxSize * this.imageWidth / this.imageHeight;
        }
      }
    }

    this.newHeight = newHeight + 'px';
    this.newWidth = newWidth + 'px';
  }
}
