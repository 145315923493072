﻿import {Injectable} from "@angular/core";
import {RestaurantUserModel} from "../../models/restaurant/user/restaurant-user.model";
import {RestaurantModel} from "../../models/restaurant/restaurant.model";
import {CityModel} from "../../models/system/city.model";

@Injectable({providedIn: "root"} as any)
export class StorageService {

  private _restaurantUser: RestaurantUserModel;
  public get restaurantUser(): RestaurantUserModel {
    if (!this._restaurantUser) {
      let storageValue = localStorage.getItem('restaurantUser');
      if (!storageValue) {
        return null;
      }
      this._restaurantUser = JSON.parse(storageValue);
    }
    return this._restaurantUser;
  }

  public set restaurantUser(restaurantUser: RestaurantUserModel) {
    localStorage.setItem('restaurantUser', JSON.stringify(restaurantUser));
    this._restaurantUser = restaurantUser;
  }


  // private _restaurant: RestaurantModel;
  // public get restaurant(): RestaurantModel {
  //   if (!this._restaurant) {
  //     let storageValue = localStorage.getItem('restaurant');
  //     if (!storageValue) {
  //       return null;
  //     }
  //     this._restaurant = JSON.parse(storageValue, this.dateTimeReviver);
  //   }
  //   return this._restaurant;
  // }
  //
  // public set restaurant(restaurant: RestaurantModel) {
  //   localStorage.setItem('restaurant', JSON.stringify(restaurant));
  //   this._restaurant = restaurant;
  // }

  private _token: string;
  public get token(): string {
    if (!this._token) {
      let storageValue = localStorage.getItem('token');
      if (!storageValue) {
        return null;
      }
      this._token = storageValue;
    }
    return this._token;
  }

  public set token(token: string) {
    localStorage.setItem('token', token);
    this._token = token;
  }

  dateTimeReviver = function (key, value) {
    if (key == 'hiddenUntilDate') {
      if (value) {
        return new Date(value);
      }
    }
    return value;
  }

  private _cities: CityModel[];
  public get cities(): CityModel[] {
    if (!this._restaurantUser) {
      let storageValue = localStorage.getItem('cities');
      if (!storageValue) {
        return null;
      }
      this._cities = JSON.parse(storageValue);
    }
    return this._cities;
  }

  public set cities(cities: CityModel[]) {
    localStorage.setItem('cities', JSON.stringify(cities));
    this._cities = cities;
  }


  clear() {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    localStorage.clear();
    if (selectedLanguage) {
      localStorage.setItem('selectedLanguage', selectedLanguage);
    }else{
      localStorage.setItem('selectedLanguage', 'de');
    }
  }
}
