﻿import { Pipe, PipeTransform } from '@angular/core';
import { CustomerModel } from 'src/app/models/customer/customer.model';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'customerFilter'})
export class CustomerFilterPipe implements PipeTransform {
  transform(list:CustomerModel[], name:string): any[] {
    if(!name){
      return list;
    }
    return list.filter(p=>p.name.toLowerCase().indexOf(name.toLowerCase())>=0 
    || p.eMail.toLowerCase().indexOf(name.toLowerCase())>=0 
    || p.phone.toLowerCase().indexOf(name.toLowerCase())>=0);
  }
}
