﻿import {Pipe, PipeTransform} from '@angular/core';
import {RestaurantItemModel} from "../../../models/super-admin/restaurant-item.model";

@Pipe({name: 'restaurantsFilter'})
export class RestaurantsFilterPipe implements PipeTransform {
  transform(list: RestaurantItemModel[], name: string): any[] {
    if (!name) {
      return list;
    }
    return list.filter(
      p => p.restaurantName.toLowerCase().indexOf(
        name.toLowerCase()) >= 0);
  }
}
