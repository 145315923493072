﻿import {Injectable} from '@angular/core';
import {HttpClient } from '@angular/common/http';
import {GlobalImageModel} from "../../models/product/global-image.model";
import {GlobalImageType} from "../../models/enums";
import {Subject} from "rxjs";



@Injectable({providedIn: 'root'} as any)
export class GlobalImageService {
  private service = 'GlobalImage';

  onImageUploadRequest = new Subject<any>();

  constructor(private http: HttpClient) {
  }

  list(type:GlobalImageType, tag?: string): Promise<GlobalImageModel[]> {
    if(tag){
      tag = tag.trim().replace(/ /g,'').replace('/','-');
      return this.http.get<GlobalImageModel[]>(`${this.service}/GetListByTag/${type}/${tag}`).toPromise();
    }
    return this.http.get<GlobalImageModel[]>(`${this.service}/GetListByTag/${type}`).toPromise();
  }
}


