﻿import {Injectable} from "@angular/core";
import {RestaurantModel} from "../../models/restaurant/restaurant.model";
import {HttpClient} from "@angular/common/http";
import {StorageService} from "../shared/storage.service";
import {RestaurantService} from "../restaurant/restaurant.service";
import {CategoryModel} from "../../models/product/category.model";
import {Observable} from "rxjs";

@Injectable({providedIn: "root"} as any)
export class CategoryService {
  private service = "category";

  constructor(private http: HttpClient, private storageService: StorageService,
              private restaurantService: RestaurantService) {

  }

  public uploadMobileImage(fileToUpload: string, fileName: string, categoryId: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('image', this.dataURIToBlob(fileToUpload), fileName);
    formData.append('categoryId', categoryId);
    return this.http.post<any>(`${this.service}/UploadMobileImage/`, formData);
  }

  public uploadWebImage(fileToUpload: string, fileName: string, categoryId: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('webImage', this.dataURIToBlob(fileToUpload), fileName);
    formData.append('categoryId', categoryId);
    return this.http.post<any>(`${this.service}/UploadWebImage/`, formData);
  }

  private dataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ia], {type: mimeString})
  }

  public updateMobileImage(id: string, imageName: string): Promise<any> {
    return this.http.put(`${this.service}/UpdateMobileImage`, {id, imageName}).toPromise();
  }

  public updateWebImage(id: string, imageName: string): Promise<any> {
    return this.http.put(`${this.service}/UpdateWebImage`, {id, imageName}).toPromise();
  }

  public async deleteMobileImage(id: string): Promise<any> {
    return this.http.delete(`${this.service}/DeleteMobileImage/${id}`).toPromise();
  }

  public async deleteWebImage(id: string): Promise<any> {
    return this.http.delete(`${this.service}/DeleteWebImage/${id}`).toPromise();
  }

  public async add(category: CategoryModel): Promise<any> {
    return this.http.post(`${this.service}/insert`, category).toPromise();
  }

  public async update(category: CategoryModel): Promise<any> {
    return this.http.put(`${this.service}/update`, category).toPromise();
  }

  public async updateOrder(oldOrder: number, newOrder: number, categoryId: string): Promise<any> {
    return this.http.put(`${this.service}/updateOrder`, {oldOrder, newOrder, categoryId}).toPromise();
  }

  public async delete(id: string): Promise<any> {
    return this.http.delete(`${this.service}/delete/${id}`).toPromise();
  }

  public async list(): Promise<CategoryModel[]> {
    return this.http.get<CategoryModel[]>(`${this.service}/list`).map((categories:CategoryModel[])=>{
      for(let category of categories) {
        if (category.serviceTime) {
          if (category.serviceTime.hiddenUntilDate) {
            category.serviceTime.hiddenUntilDate = new Date(category.serviceTime.hiddenUntilDate)
          }
        }
        if(category.products){
          for(let product of category.products){
            if (product.serviceTime) {
              if (product.serviceTime.hiddenUntilDate) {
                product.serviceTime.hiddenUntilDate = new Date(product.serviceTime.hiddenUntilDate)
              }
            }
          }
        }
      }
      return categories;
    }).toPromise();
  }
}
