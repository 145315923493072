import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { StorageService } from "../../services/shared/storage.service";

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private storageService: StorageService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.storageService.restaurantUser) {

      if (route.data.permissions) {
        if (route.data.permissions.indexOf(
          "NotificationSubscription") !== -1 && !this.storageService.restaurantUser.notificationSubscription) {
          this.router.navigate(["/home"]);
          return false;
        }
        else if (route.data.permissions.indexOf(
          "SuperAdmin") !== -1 && !this.storageService.restaurantUser.isSuperAdmin) {
          this.router.navigate(["/home"]);
          return false;
        }
      }
      return true;
    }

    this.router.navigate(["/login"]);
    return false;
  }
}
