import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" } as any)
export class TranslatorService {

  private defaultLanguage: string = 'de';

  private availablelangs = [
    { code: 'de', text: 'Deutsch' },
    { code: 'en', text: 'English' },
    { code: 'tr', text: 'Turkish' },
    { code: 'fr', text: 'français' },
    { code: 'it', text: 'italiano' }

  ];

  onLanguageChanged = new Subject<any>();

  constructor(public translate: TranslateService) {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      this.useLanguage(selectedLanguage);
    }
    else {
      // if (!translate.getDefaultLang()) {
      //   translate.setDefaultLang(this.defaultLanguage);
      // }
      this.useLanguage('de');
    }
  }

  useLanguage(lang: string = null) {
    const selectedLanguage = lang || this.translate.getDefaultLang();
    this.translate.use(selectedLanguage);
    localStorage.setItem('selectedLanguage', selectedLanguage);
  }

  getAvailableLanguages() {
    return this.availablelangs;
  }

  get(key: string) {
    return this.translate.get(key)
  }

  getSelectedLanguage(): string {
    return localStorage.getItem('selectedLanguage');
  }
}
