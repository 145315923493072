import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash';

@Pipe({
    name: 'webContentFilter'
})
export class WebContentFilterPipe implements PipeTransform {

    transform(list: any[], filters: Object) {

        const keys = Object.keys(filters).filter(key => filters[key]);
        const filterUser = user => keys.every(key => user[key].toString().toUpperCase().indexOf(filters[key].toString().toUpperCase()) > -1);

        return keys.length ? list.filter(filterUser) : list;


    }

}