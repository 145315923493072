﻿import {RestaurantUserLoginModel} from "../../models/login/restaurant-user-login.model";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {RestaurantUserLoginResultModel} from "../../models/login/restaurant-user-login-result.model";
import {RestaurantService} from "../restaurant/restaurant.service";
import {RestaurantUserModel} from "../../models/restaurant/user/restaurant-user.model";
import {JwtHelperService} from "@auth0/angular-jwt";
import {StorageService} from "../shared/storage.service";
import {RestaurantUserUpdateModel} from "../../models/restaurant/user/restaurant-user-update.model";
import {TranslatorService} from "../../core/translator/translator.service";


@Injectable({providedIn: "root"} as any)
export class RestaurantUserService {
  private service = "RestaurantUser";

  constructor(private http: HttpClient, private restaurantService: RestaurantService, private storageService: StorageService, private translatorService: TranslatorService) {

  }


  login(restaurantUserLoginModel: RestaurantUserLoginModel): any {
    const helper = new JwtHelperService();

    return this.http.post<RestaurantUserLoginResultModel>(`${this.service}/login`, restaurantUserLoginModel).map(result => {
      this.storageService.token = result.token;

      let restaurantUser = new RestaurantUserModel();
      Object.assign(restaurantUser, JSON.parse(helper.decodeToken(result.token).sub));
      this.storageService.restaurantUser = restaurantUser;

      return result;
    })
  }

  logout() {
    const selectedLanguage = this.translatorService.getSelectedLanguage();
    this.storageService.clear();
    this.translatorService.useLanguage(selectedLanguage);
  }

  resetPassword(eMail: string): Promise<boolean> {
    return this.http.post<boolean>(`${this.service}/ResetPassword`, eMail).toPromise();
  }

  update(restaurantUserUpdate: RestaurantUserUpdateModel): Promise<boolean> {
    return this.http.put<boolean>(`${this.service}/Update`, restaurantUserUpdate).toPromise();
  }
}
