﻿import {Component, Injector, OnInit} from '@angular/core';
import {SettingsService} from '../../../core/settings/settings.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {CustomValidators} from 'ng2-validation';
import {TranslatorService} from "../../../core/translator/translator.service";
import {RestaurantUserLoginModel} from "../../../models/login/restaurant-user-login.model";
import {BaseComponent} from "../../../core/component/base-component";
import {RestaurantUserService} from "../../../services/restaurant-user/restaurant-user.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {

  valForm: FormGroup;
  showErrorMessage: boolean = false;
  errorMessage: string;
  isButtonDisabled: boolean = false;

  constructor(injector: Injector, private settings: SettingsService, fb: FormBuilder,
              private translator: TranslatorService, private restaurantUserService: RestaurantUserService) {
    super(injector);

    this.valForm = fb.group({
      'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
      'password': [null, Validators.required]
    });

  }

  async submitForm($ev, value: any) {
    if (!this.valForm.valid) {
      this.showErrorMessage = true;
      this.translator.get('shared.PleaseFillTheForm').subscribe(result => {
        if (!result) {
          this.errorMessage = 'Please fill the form.';
          return;
        }
        this.errorMessage = result;
      });
      return;
    }
    this.isButtonDisabled = true;
    let userLoginModel = new RestaurantUserLoginModel();
    userLoginModel.eMail = this.valForm.controls['email'].value;
    userLoginModel.password = this.valForm.controls['password'].value;

    this.restaurantUserService.login(userLoginModel).subscribe(result => {
      this.isButtonDisabled=false;
      this.router.navigate(['/home']);
      return;
    },(error)=>{
      this.isButtonDisabled=false;
      throw error;
    })

  }

  ngOnInit() {

  }

}
