﻿import { RestaurantUserType } from "../../enums";

export class RestaurantUserModel {
  id: string;
  restaurantId: string;
  languageId: string;
  eMail: string;
  name: string;
  surname: string;
  isEMailApproved: boolean;
  type: RestaurantUserType;
  notificationSubscription: boolean;
  isSuperAdmin: boolean;
  activeRestaurantName: string;
}
