import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap";


@Component({
    selector: 'modal-content',
    styleUrls: ['./modalcontent.component.scss'],
    templateUrl: './modalcontent.component.html'
})
export class ModalContentComponent implements OnInit, OnChanges {
    public title: string;
    public content: string;
    public list: any[] = [];

    constructor(public bsModalRef: BsModalRef) {}

    public ngOnInit() {
        console.log('BsModalRef from modal content component', this.bsModalRef);
    }

  ngOnChanges(changes: SimpleChanges): void {
      console.log(changes);
  }
}
